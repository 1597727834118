import { AnimationItem, LottiePlayer } from 'lottie-web';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';

let Lottie: LottiePlayer;

interface AnimationProps {
  animation: object;
  threshold?: number;
  loop?: boolean;
  autoplay?: boolean;
}

type LottieImport = typeof import('lottie-web');
const loadLottie = (): Promise<LottieImport> => {
  return import('lottie-web');
};

export default function LottieAnimation(props: StylableProp<AnimationProps>) {
  const [loaded, setLoaded] = useState(false);
  const { ref, inView } = useInView({ threshold: props.threshold ?? 0 });
  const animElRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<AnimationItem>();

  useEffect(() => {
    loadLottie().then((e) => {
      Lottie = e.default;
      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (!loaded || !Lottie) {
      return;
    }

    if (!animationRef.current) {
      animationRef.current = Lottie.loadAnimation({
        container: animElRef.current!,
        animationData: props.animation,
        loop: props.loop ?? false,
        autoplay: props.autoplay ?? false
      });
    }

    if (inView) {
      animationRef.current?.goToAndPlay(0);
    } else {
      animationRef.current?.stop();
    }
  }, [inView, loaded]);

  return (
    <div ref={ref} className={props.className}>
      <div
        ref={animElRef}
        className={`${style.animation} ${props.className}`}
        style={props.style}
      ></div>
    </div>
  );
}
